<template>
  <v-dialog
    v-model="modalShow"
    persistent
    max-width="60%"
    scrollable
    class="z-index-lg"
  >
    <v-card tile>
      <v-card-title class="grey lighten-3 pa-0 ma-0" style="z-index: 10000 !important">
        <v-list class="fill-width">
          <v-list-item>
            <v-list-item-content>
              <span class="text-h5 font-weight-bold primary--text">Detalles de Pedidos</span>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="cerrar()" style="position: absolute; right:15px; top: 5px">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-title>
      <v-card-text class="px-0 pb-0">
        <v-data-table
        class="table-pagos elevation-1"
        item-key="cod_pago"
        :headers="headerPayment"
        :items="items"
        :footer-props="{itemsPerPageText: 'Mostrar'}"
        sort-by="Fecha"
        :sort-desc="true"
        >
        <template v-slot:item.Número="{ item }">
          <span class="primary--text font-weight-bold" v-text="item.Número" />
       </template>
        <template v-slot:item.cantidad_total="{ item }">
          <span class="primary--text font-weight-bold" v-text="item.cantidad_total" />
       </template>
        <template v-slot:item.Fecha="{item}">
          <span class="font-weight-medium blue-grey--text">{{item.Fecha | smartDate}}</span>
        </template>
        <template v-slot:item.Vendedor="{item}">
          <span
            class="blue-grey--text font-weight-medium"
            :class="{'display': !$vuetify.breakpoint.mobile, 'subtitle-1': $vuetify.breakpoint.mobile }"
            v-text="item.Vendedor" />
          <!-- <span
            class="font-weight-bold ml-1"
            v-text="'('+ item.co_ven + ')'" /> -->
        </template>
        <template v-slot:item.TipoPrecio="{ item }">
          <div class="d-flex justify-center">
            Nro. {{item.TipoPrecio | precio_venta}}
          </div>
        </template>
        <template v-slot:item.Procesado="{ item }">
          <div class="d-flex justify-center">
            <v-chip
              small
              :color="item.Procesado ==='N' ? 'red' : 'success'"
              dark v-text="item.Procesado"
            ></v-chip>
          </div>
        </template>
        <template #loading>
          <v-skeleton-loader
            type="table-tbody"
          ></v-skeleton-loader>
        </template>
        <template v-slot:no-data>
          <div class="text-center pa-4">
            <span class="blue-grey--text font-italic">No se encontraron Pedidos Registrados</span>
          </div>
        </template>
      </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name:'ModalDetallesPedidos',
  props:{
    value: Boolean,
    items:{
      type: Array,
      default: () => ([]),
    },
  },
  data () {
    return {
      modalShow: this.value,
    }
  },
  watch: {
    modalShow (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.modalShow = val
    },
  },
  computed:{
    headerPayment () {
      return [
        { text: 'Número', value: 'Número', class: 'header-data', width: '120' },
        { text: 'Fecha', value: 'Fecha', class: 'header-data', width: '120'},
        {
          text: 'Vendedor',
          align: 'start',
          value: 'Vendedor',
          class: 'header-data',
          // width: '220'
        },
        { text: 'Precio Venta', value: 'TipoPrecio', class: 'header-data', align: ' text-center',},
        { text: 'Procesado', value: 'Procesado', class: 'header-data', align: ' text-center' },
        { text: 'Cajas', value: 'cantidad_total', class: 'header-data', align: ' text-center' },
      ]
    },
  },
  methods: {
    cerrar () {
      this.modalShow = false;
    },
  },
  filters: {
    formatNumero(num) {
      return num !== null && num !== 'null' ? num.replace(/,/, '.') : '';
    },
    precio_venta (price) {
      if(price != null) {
        const numbreLista = price.toString().split('');
        return numbreLista[1];
      }

      return price;
    }
  }
}
</script>
<style lang="sass">
.z-index-lg
  z-index: 1000 !important
</style>
