var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"z-index-lg",attrs:{"persistent":"","max-width":"60%","scrollable":""},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"grey lighten-3 pa-0 ma-0",staticStyle:{"z-index":"10000 !important"}},[_c('v-list',{staticClass:"fill-width"},[_c('v-list-item',[_c('v-list-item-content',[_c('span',{staticClass:"text-h5 font-weight-bold primary--text"},[_vm._v("Detalles de Pedidos")])]),_c('v-list-item-action',[_c('v-btn',{staticStyle:{"position":"absolute","right":"15px","top":"5px"},attrs:{"icon":""},on:{"click":function($event){return _vm.cerrar()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1),_c('v-card-text',{staticClass:"px-0 pb-0"},[_c('v-data-table',{staticClass:"table-pagos elevation-1",attrs:{"item-key":"cod_pago","headers":_vm.headerPayment,"items":_vm.items,"footer-props":{itemsPerPageText: 'Mostrar'},"sort-by":"Fecha","sort-desc":true},scopedSlots:_vm._u([{key:"item.Número",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text font-weight-bold",domProps:{"textContent":_vm._s(item.Número)}})]}},{key:"item.cantidad_total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text font-weight-bold",domProps:{"textContent":_vm._s(item.cantidad_total)}})]}},{key:"item.Fecha",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium blue-grey--text"},[_vm._v(_vm._s(_vm._f("smartDate")(item.Fecha)))])]}},{key:"item.Vendedor",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blue-grey--text font-weight-medium",class:{'display': !_vm.$vuetify.breakpoint.mobile, 'subtitle-1': _vm.$vuetify.breakpoint.mobile },domProps:{"textContent":_vm._s(item.Vendedor)}})]}},{key:"item.TipoPrecio",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" Nro. "+_vm._s(_vm._f("precio_venta")(item.TipoPrecio))+" ")])]}},{key:"item.Procesado",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-chip',{attrs:{"small":"","color":item.Procesado ==='N' ? 'red' : 'success',"dark":""},domProps:{"textContent":_vm._s(item.Procesado)}})],1)]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center pa-4"},[_c('span',{staticClass:"blue-grey--text font-italic"},[_vm._v("No se encontraron Pedidos Registrados")])])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }